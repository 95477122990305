import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridViewIcon from '@mui/icons-material/GridView';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

import MenuButton from '../buttons/MenuButton/MenuButton';
import MainButton from '../buttons/MainButton/MainButton';
import { useAppDispatch } from '../../hooks/redux';
import { logout } from '../../store/slices/auth';
import styles from './SideMenu.module.css';

const pages = [
  {
    id: 10,
    name: 'Dashboard',
    path: '/dashboard',
    icon: <GridViewIcon />,
    end: true,
  },
  {
    id: 1,
    name: 'Books',
    path: '/dashboard/books',
    icon: <LibraryBooksIcon />,
  },
  {
    id: 2,
    name: 'Create a new PDF book (deprecated)',
    path: '/dashboard/upload-book',
    icon: <MenuBookIcon />,
  },
  {
    id: 3,
    name: 'Create a new book with book builder',
    path: '/dashboard/create-book-page',
    icon: <AutoStoriesIcon />,
  },
  {
    id: 4,
    name: 'Categories',
    path: '/dashboard/categories',
    icon: <FormatListBulletedIcon />,
  },
];

const SideMenu: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsOpen(false);
  };

  const handleLogOut = () => {
    dispatch(logout());
  };

  return (
    <>
      <div className={styles.menuIcon} onClick={toggleMenu}>
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </div>
      <div className={`${styles.container} ${isOpen ? styles.open : ''}`}>
        <div className={styles.imageContainer}>
          <img className={styles.image} src="/app-image.jpg" alt="App" />
        </div>
        <Divider />
        <div>
          {pages.map((page) => (
            <MenuButton
              key={page.id}
              onClick={() => handleNavigation(page.path)}
              {...page}
            />
          ))}
        </div>
        <div>
          <MainButton label="Log out" onClick={handleLogOut} warning />
        </div>
      </div>
    </>
  );
};

export default SideMenu;
