import { FC, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";

import InputField from "../../components/InputField/InputField";
import MainButton from "../../components/buttons/MainButton/MainButton";
import CategorySelector from "../../components/CategorySelector/CategorySelector";
import ImageUploader from "../../components/ImageUploader/ImageUploader";

import { useAppDispatch } from "../../hooks/redux";
import { uploadBuilderBook } from "../../store/thunks/books";
import { fetchCategories } from "../../store/thunks/categories";
import { UploadBookBuilderPayload } from "../../store/types";
import { IBook } from "../../types/books";

import styles from "./CreateNewBook.module.css";
import MiniButton from "../../components/buttons/MiniButton/MiniiButton";

const CreateNewBook: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [submitError, setSubmitError] = useState<string | null>(null);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<IBook>({
    defaultValues: {
      title: "",
      author: "",
      description: "",
      categories: [],
      minAge: 0,
      price: 0,
      backCoverURI: "",
      externalStoreURl: "",
      externalStoreCoverURl: "",
      partNumber: "",
      pageMode: "horizontal",
    },
  });

  const [coverImage, setCoverImage] = useState<File | null>(null);
  const uploadCoverImage = (file: File | null) => {
    setCoverImage(file);
  };

  const [scrollImage, setScrollImage] = useState<File | null>(null);
  const uploadScrollImg = (file: File | null) => {
    setScrollImage(file);
  };

  const [externalCoverOption, setExternalCoverOption] = useState<
    "url" | "file"
  >("url");
  const [externalCoverFile, setExternalCoverFile] = useState<File | null>(null);
  const uploadExternalCoverFile = (file: File | null) => {
    setExternalCoverFile(file);
  };

  const onSubmit = async (data: IBook) => {
    const payload: UploadBookBuilderPayload = {
      data: {
        ...data,
        externalStoreCoverURl:
          externalCoverOption === "url" ? data.externalStoreCoverURl : "",
      },
      files: {
        cover: coverImage,
        scrollImg: scrollImage,
        externalStoreCover:
          externalCoverOption === "file" ? externalCoverFile : null,
      },
    };
    console.log("submit payload: ", payload);

    try {
      await dispatch(uploadBuilderBook(payload)).unwrap();
      navigate("/dashboard/books");
    } catch (error: any) {
      console.error("Failed to create book:", error);
      setSubmitError(
        error.message || "Failed to create the book. Please try again."
      );
    }
  };

  const backNavigate = () => {
    navigate(-1);
  };

  return (
    <div className={styles.bookUpload}>
      <h1>Create New Book</h1>
      <div className={styles.bookUpload__back}>
        <MiniButton onClick={backNavigate} secondary>
          <CloseIcon />
        </MiniButton>
      </div>
      <div className={styles.imagesContainer}>
        <div className={styles.imageContainer}>
          <h3>Choose cover image</h3>
          <ImageUploader
            image={coverImage}
            imageUrl={null}
            onImageChange={uploadCoverImage}
            editMode
            inputId="coverImage"
          />
        </div>
        <div className={styles.imageContainer}>
          <h3>Choose scroll image</h3>
          <ImageUploader
            image={scrollImage}
            imageUrl={null}
            onImageChange={uploadScrollImg}
            editMode
            inputId="scrollImage"
          />
        </div>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.inputsContainer}
      >
        {submitError && <div className={styles.submitError}>{submitError}</div>}

        <div className={styles.externalStoreCover}>
          <h3>External Store Cover</h3>
          <div className={styles.toggleGroup}>
            <label>
              <input
                type="radio"
                value="url"
                checked={externalCoverOption === "url"}
                onChange={() => {
                  setExternalCoverOption("url");
                  setExternalCoverFile(null);
                  setValue("externalStoreCoverURl", "");
                }}
              />
              <span>URL</span>
            </label>
            <label>
              <input
                type="radio"
                value="file"
                checked={externalCoverOption === "file"}
                onChange={() => {
                  setExternalCoverOption("file");
                  setValue("externalStoreCoverURl", "");
                }}
              />
              <span>Upload File</span>
            </label>
          </div>

          <div className={styles.externalCoverContent}>
            {externalCoverOption === "url" ? (
              <div className={styles.urlInput}>
                <label>
                  External Store Cover URL
                  <Controller
                    name="externalStoreCoverURl"
                    control={control}
                    rules={{
                      pattern: {
                        value:
                          /^(https?:\/\/)?([\w\d\-]+\.)+\w{2,}(\/.+)*\/?$/i,
                        message: "Invalid URL format",
                      },
                    }}
                    render={({ field }) => (
                      <InputField
                        type="url"
                        placeholder="External Store Cover URL - optional"
                        {...field}
                        error={errors.externalStoreCoverURl?.message}
                      />
                    )}
                  />
                </label>
              </div>
            ) : (
              <div className={styles.fileUploadContainer}>
                <ImageUploader
                  image={externalCoverFile}
                  imageUrl={null}
                  onImageChange={uploadExternalCoverFile}
                  editMode
                  inputId="externalStoreCoverFile"
                />
              </div>
            )}
          </div>
        </div>

        <div className={styles.inputGroup}>
          <label>
            Title
            <Controller
              name="title"
              control={control}
              rules={{ required: "Title is required" }}
              render={({ field }) => (
                <InputField
                  type="text"
                  placeholder="Title"
                  {...field}
                  error={errors.title?.message}
                />
              )}
            />
          </label>
        </div>
        <div className={styles.inputGroup}>
          <label>
            Author
            <Controller
              name="author"
              control={control}
              rules={{ required: "Author is required" }}
              render={({ field }) => (
                <InputField
                  type="text"
                  placeholder="Author"
                  {...field}
                  error={errors.author?.message}
                />
              )}
            />
          </label>
        </div>
        <div className={styles.inputGroup}>
          <label>
            Description
            <Controller
              name="description"
              control={control}
              rules={{ required: "Description is required" }}
              render={({ field }) => (
                <InputField
                  type="text"
                  placeholder="Description"
                  textarea
                  {...field}
                  error={errors.description?.message}
                />
              )}
            />
          </label>
        </div>
        <div className={styles.inputGroup}>
          <label>
            Categories
            <Controller
              name="categories"
              control={control}
              rules={{ required: "At least one category is required" }}
              render={({ field, fieldState }) => (
                <CategorySelector
                  field={field}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </label>
        </div>
        <div className={styles.inputGroup}>
          <label>
            Minimal age
            <Controller
              name="minAge"
              control={control}
              rules={{ required: "Minimal age is required" }}
              render={({ field }) => (
                <InputField
                  type="number"
                  placeholder="Minimal age"
                  {...field}
                  error={errors.minAge?.message}
                />
              )}
            />
          </label>
        </div>
        <div className={styles.inputGroup}>
          <label>
            Price
            <Controller
              name="price"
              control={control}
              rules={{ required: "Price is required" }}
              render={({ field }) => (
                <InputField
                  type="number"
                  placeholder="Price"
                  {...field}
                  error={errors.price?.message}
                />
              )}
            />
          </label>
        </div>
        <div className={styles.inputGroup}>
          <label>
            Back Cover URI
            <Controller
              name="backCoverURI"
              control={control}
              render={({ field }) => (
                <InputField
                  type="text"
                  placeholder="Back Cover URI - optional"
                  {...field}
                  error={errors.backCoverURI?.message}
                />
              )}
            />
          </label>
        </div>
        <div className={styles.inputGroup}>
          <label>
            External Store URL
            <Controller
              name="externalStoreURl"
              control={control}
              render={({ field }) => (
                <InputField
                  type="url"
                  placeholder="External Store URL - optional"
                  {...field}
                  error={errors.externalStoreURl?.message}
                />
              )}
            />
          </label>
        </div>

        <div className={styles.inputGroup}>
          <label>
            Part Number
            <Controller
              name="partNumber"
              control={control}
              render={({ field }) => (
                <InputField
                  type="text"
                  placeholder="Part Number - optional"
                  {...field}
                  error={errors.partNumber?.message}
                />
              )}
            />
          </label>
        </div>
        <div className={styles.inputGroup}>
          <label>Page Mode</label>
          <div className={styles.radioGroup}>
            <Controller
              name="pageMode"
              control={control}
              rules={{ required: "Please select a page mode" }}
              render={({ field }) => (
                <>
                  <div className={styles.radioItem}>
                    <input
                      type="radio"
                      name="pageMode"
                      value="horizontal"
                      checked={field.value === "horizontal"}
                      onChange={() => field.onChange("horizontal")}
                    />
                    <span>Horizontal</span>
                  </div>
                  <div className={styles.radioItem}>
                    <input
                      type="radio"
                      name="pageMode"
                      value="vertical"
                      checked={field.value === "vertical"}
                      onChange={() => field.onChange("vertical")}
                    />
                    <span>Vertical</span>
                  </div>
                </>
              )}
            />
          </div>
          {errors.pageMode && (
            <span className={styles.error}>{errors.pageMode.message}</span>
          )}
        </div>
        <div className={styles.buttonContainer}>
          <MainButton label="Create a new book" type="submit" />
        </div>
      </form>
    </div>
  );
};

export default CreateNewBook;
