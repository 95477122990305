import React, { FC, useEffect, useState } from "react";
import BookSelector from "../../components/BookSelector/BookSelector";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchBooks } from "../../store/thunks/books";
import PageConstructor from "../../components/PageConstructor/PageConstructor";
import { PageToEdit } from "../../types/books";
import {
  setSelectedBook,
  setSelectedBookPages,
} from "../../store/slices/books";
import { getSelectedBookSelector } from "../../store/selectors/books";

const initialPageEdit: PageToEdit = {
  isEdit: false,
  pageNumber: null,
};

const CreateBookPage: FC = () => {
  const dispatch = useAppDispatch();

  const selectedBook = useAppSelector(getSelectedBookSelector);
  console.log("🚀 ~ selectedBook:", selectedBook);

  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [pageToEdit, setPageToEdit] = useState<PageToEdit>(initialPageEdit);

  const enableEditMode = (pageNumber: number) => {
    setPageToEdit({ isEdit: true, pageNumber });
    setShowEditor(true);
  };

  useEffect(() => {
    dispatch(setSelectedBook(null));
    dispatch(setSelectedBookPages([]));

    dispatch(fetchBooks());
  }, [dispatch]);

  return showEditor ? (
    <PageConstructor
      pageToEdit={pageToEdit}
      pageMode={selectedBook?.pageMode}
      onHide={() => setShowEditor(false)}
    />
  ) : (
    <BookSelector
      onShow={() => {
        setShowEditor(true);
        setPageToEdit(initialPageEdit);
      }}
      editMode={enableEditMode}
    />
  );
};

export default CreateBookPage;
