import React, { useState } from "react";
import { Link } from "react-router-dom";

import styles from "./BookPreviewCard.module.css";

interface BookPreviewCardProps {
  _id: string;
  title: string;
  author?: string;
  description?: string;
  pageMode?: "horizontal" | "vertical";
  coverURI?: string;
  isSelector?: boolean;
  onClick?: () => void;
  isPublished?: boolean;
  rating?: number;
  onEdit?: () => void;
  onDelete?: () => void;
}

const BookPreviewCard: React.FC<BookPreviewCardProps> = ({
  _id,
  title,
  author,
  description,
  pageMode,
  coverURI,
  isSelector,
  onClick = () => null,
  isPublished,
  rating,
  onEdit,
  onDelete,
}) => {
  const placeholderImage = "/image-not-found.webp";
  const [imgSrc, setImgSrc] = useState(coverURI ? coverURI : placeholderImage);

  const handleError = () => {
    setImgSrc(placeholderImage);
  };

  const cardContent = (
    <div className={styles.content}>
      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          src={imgSrc}
          alt={`${title} Cover`}
          width={200}
          height={200}
          onError={handleError}
        />
      </div>
      <div className={styles.details}>
        <div className={styles.detailItem}>
          <span className={styles.label}>Title:</span>
          <span className={styles.value}>{title}</span>
        </div>
        {author && (
          <div className={styles.detailItem}>
            <span className={styles.label}>Author:</span>
            <span className={styles.value}>{author}</span>
          </div>
        )}
        {description && (
          <div className={styles.detailItem}>
            <span className={styles.label}>Description:</span>
            <span className={styles.value}>{description}</span>
          </div>
        )}
        <div className={styles.detailItem}>
          <span className={styles.label}>Status:</span>
          <span className={styles.value}>
            {isPublished ? "Published" : "Not Published"}
          </span>
        </div>
        {pageMode && (
          <div className={styles.detailItem}>
            <span className={styles.label}>Page Mode:</span>
            <span className={styles.value}>
              {pageMode === "horizontal" ? "Horizontal" : "Vertical"}
            </span>
          </div>
        )}
        {rating !== undefined && (
          <div className={styles.detailItem}>
            <span className={styles.label}>Rating:</span>
            <span className={styles.value}>{rating} ⭐</span>
          </div>
        )}
      </div>

      {(onEdit || onDelete) && (
        <div className={styles.buttonPanel}>
          {onEdit && (
            <button
              className={styles.button}
              onClick={onEdit}
              aria-label="Edit"
            >
              Edit
            </button>
          )}
          {onDelete && (
            <button
              className={styles.button}
              onClick={onDelete}
              aria-label="Delete"
            >
              Delete
            </button>
          )}
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.card} onClick={onClick}>
      {isSelector ? (
        cardContent
      ) : (
        <Link className={styles.link} to={`/dashboard/books/${_id}`}>
          {cardContent}
        </Link>
      )}
    </div>
  );
};

export default BookPreviewCard;
