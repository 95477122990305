import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { signIn } from "../thunks/auth";
import { IAuthResponce } from "../types";

interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
}

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
    },
    logout: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      signIn.fulfilled,
      (
        state,
        action: PayloadAction<{ accessToken: string; refreshToken: string }>
      ) => {
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
      }
    );
  },
});

export const { setAccessToken, setRefreshToken, logout } = authSlice.actions;
export default authSlice.reducer;
