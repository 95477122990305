import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import styles from "./CategoryCard.module.css";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import MiniButton from "../buttons/MiniButton/MiniiButton";
import InputField from "../InputField/InputField";

interface CategoryCardProps {
  _id: string;
  name: string;
  onEdit: (id: string, name: string) => void;
  onDelete: (id: string) => void;
  existingCategoryNames: string[];
}

const CategoryCard: React.FC<CategoryCardProps> = ({
  _id,
  name,
  onEdit,
  onDelete,
  existingCategoryNames,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      newName: name,
    },
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleSave = (data: { newName: string }) => {
    onEdit(_id, data.newName);
    setIsEditing(false);
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setTimeout(() => {
      onDelete(_id);
    }, 300);
  };

  const validateUniqueName = (value: string) => {
    if (existingCategoryNames.includes(value) && value !== name) {
      return "Category name already exists";
    }
    return true;
  };

  return (
    <div
      className={`${styles.categoryCard} ${isEditing ? styles.editing : ""} ${
        isDeleting ? styles.fadeOut : ""
      }`}
    >
      <div className={styles.cardContent}>
        <div className={styles.categoryCardDetails}>
          <span className={styles.categoryCardName}>{name}</span>
        </div>
        <div className={styles.categoryCardOptions}>
          <MiniButton onClick={() => setIsEditing(!isEditing)}>
            <EditIcon />
          </MiniButton>
          <MiniButton onClick={handleDelete} secondary>
            <DeleteIcon />
          </MiniButton>
        </div>
      </div>
      {isEditing && (
        <form
          onSubmit={handleSubmit(handleSave)}
          className={styles.editContainer}
        >
          <Controller
            name="newName"
            control={control}
            rules={{
              required: "Category name is required",
              validate: validateUniqueName,
            }}
            render={({ field }) => (
              <InputField
                type="text"
                placeholder="New category name"
                error={errors.newName?.message}
                {...field}
              />
            )}
          />
          <div className={styles.buttonsContainer}>
            <MiniButton type="submit">
              <SaveIcon />
            </MiniButton>
          </div>
        </form>
      )}
    </div>
  );
};

export default CategoryCard;
