// import { FC, ChangeEvent, useState, useEffect } from "react";
// import MainButton from "../../components/buttons/MainButton/MainButton";
// import styles from "./ImageUploader.module.css";

// interface ImageUploaderProps {
//   image: File | null;
//   imageUrl: string | null;
//   onImageChange: (file: File | null) => void;
//   editMode: boolean;
// }

// const ImageUploader: FC<ImageUploaderProps> = ({
//   image,
//   imageUrl,
//   onImageChange,
//   editMode,
// }) => {
//   const placeholderImage = "/image-picker.webp";
//   const notAvailableImage = "/image-not-found.webp";
//   const [imgSrc, setImgSrc] = useState<string>(imageUrl || placeholderImage);

//   useEffect(() => {
//     if (image) {
//       setImgSrc(URL.createObjectURL(image));
//     } else {
//       setImgSrc(imageUrl || placeholderImage);
//     }
//   }, [image, imageUrl]);

//   const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
//     if (e.target.files && e.target.files.length > 0) {
//       onImageChange(e.target.files[0]);
//     }
//   };

//   const handleError = () => {
//     setImgSrc(notAvailableImage);
//   };

//   const removeImage = () => {
//     onImageChange(null);
//     setImgSrc(placeholderImage);
//   };

//   return (
//     <div className={styles.imageContainer}>
//       <img
//         className={styles.image}
//         src={imgSrc}
//         alt="Book"
//         width={300}
//         height={300}
//         onError={handleError}
//       />
//       {image && (
//         <button onClick={removeImage} className={styles.removeButton}>
//           Remove Image
//         </button>
//       )}
//       {editMode && (
//         <div className={styles.uploadButtonContainer}>
//           <input
//             type="file"
//             id="file-upload"
//             onChange={handleImageChange}
//             accept="image/*"
//             style={{ display: "none" }}
//           />
//           <MainButton
//             label="Upload Image"
//             onClick={() => document.getElementById("file-upload")?.click()}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default ImageUploader;

import { FC, ChangeEvent, useState, useEffect } from 'react';
import MainButton from '../../components/buttons/MainButton/MainButton';
import styles from './ImageUploader.module.css';

interface ImageUploaderProps {
  image: File | null;
  imageUrl: string | null;
  onImageChange: (file: File | null) => void;
  editMode: boolean;
  inputId: string;
}

const ImageUploader: FC<ImageUploaderProps> = ({
  image,
  imageUrl,
  onImageChange,
  editMode,
  inputId,
}) => {
  const placeholderImage = '/image-picker.webp';
  const notAvailableImage = '/image-not-found.webp';
  const [imgSrc, setImgSrc] = useState<string>(imageUrl || placeholderImage);

  useEffect(() => {
    let objectUrl: string | undefined;

    if (image) {
      objectUrl = URL.createObjectURL(image);
      setImgSrc(objectUrl);
    } else {
      setImgSrc(imageUrl || placeholderImage);
    }

    // Очистка созданного object URL для предотвращения утечек
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [image, imageUrl]);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      onImageChange(e.target.files[0]);
    }
  };

  const handleError = () => {
    setImgSrc(notAvailableImage);
  };

  const removeImage = () => {
    onImageChange(null);
    setImgSrc(placeholderImage);
  };

  return (
    <div className={styles.imageContainer}>
      <img
        className={styles.image}
        src={imgSrc}
        alt="Book"
        width={300}
        height={300}
        onError={handleError}
      />
      {image && (
        <button onClick={removeImage} className={styles.removeButton}>
          Remove Image
        </button>
      )}
      {editMode && (
        <div className={styles.uploadButtonContainer}>
          <input
            type="file"
            id={inputId}
            onChange={handleImageChange}
            accept="image/*"
            style={{ display: 'none' }}
          />
          <MainButton
            label="Upload Image"
            onClick={() => document.getElementById(inputId)?.click()}
          />
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
