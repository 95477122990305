import { FC } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import LoginPage from './pages/LoginPage/LoginPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import DashboardLayout from './components/DashboardLayout/DashboardLayout';
import Books from './pages/BooksPage/BooksPage';
import Book from './pages/BookPage/BookPage';
import BookUploadPage from './pages/UploadBookPage/UploadBookPage';
import CategoriesPage from './pages/CategoriesPage/CategoriesPage';
import NotFound from './pages/NotFoundPage/NotFoundPage';
import CreateBookPage from './pages/CreateBookPage/CreateBookPage';
import CreateNewBook from './pages/CreateNewBook/CreateNewBook';

const App: FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <DashboardLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<DashboardPage />} />
            <Route path="books" element={<Books />} />
            <Route path="books/:id" element={<Book />} />
            <Route path="upload-book" element={<BookUploadPage />} />
            <Route path="create-book-page" element={<CreateBookPage />} />
            <Route path="categories" element={<CategoriesPage />} />
            <Route path="create-new-book" element={<CreateNewBook />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
