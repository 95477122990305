import { RootState } from '../store';

export const booksSelector = ({ books }: RootState) => books.books;
export const searchBookResultSelector = ({ books }: RootState) =>
  books.searchResults;
export const bookByIdSelector = (state: RootState, id: string | undefined) =>
  id ? state.books.books.find((book) => book._id === id) : undefined;
export const getSelectedBookSelector = ({ books }: RootState) =>
  books.selectedBook;
export const getSelectedBookPagesSelector = ({ books }: RootState) =>
  books.selectedBookPages;
