import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import MainButton from "../buttons/MainButton/MainButton";
import PageThumbnail from "../PageThumbnail/PageThumbnail";

import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  booksSelector,
  getSelectedBookPagesSelector,
  getSelectedBookSelector,
  searchBookResultSelector,
} from "../../store/selectors/books";
import {
  searchBooks,
  setSelectedBook,
  setSelectedBookPages,
} from "../../store/slices/books";
import { deletePage, fetchBookPages } from "../../store/thunks/books";
import { IBook, BookPage } from "../../types/books";

import styles from "./BookSelector.module.css";
import MiniButton from "../buttons/MiniButton/MiniiButton";
import BookPreviewCard from "../BookPreviewCard/BookPrewiewCard";
import InputField from "../InputField/InputField";

interface BookSelectorProps {
  onShow: () => void;
  editMode: (pageNumber: number) => void;
}

const BookSelector: FC<BookSelectorProps> = ({ onShow, editMode }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const books = useAppSelector(searchBookResultSelector);
  console.log("🚀 ~ books:", books);
  const selectedBook = useAppSelector(getSelectedBookSelector);
  const selectedBookPages = useAppSelector(getSelectedBookPagesSelector);

  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (selectedBook) {
      dispatch(fetchBookPages(selectedBook._id));
    }
  }, [selectedBook, dispatch]);

  const handleSearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    dispatch(searchBooks(value));
  };

  const handleClick = (book: IBook) => {
    setIsTransitioning(true);
    setTimeout(() => {
      dispatch(setSelectedBook(book));
      setIsTransitioning(false);
    }, 500);
  };

  const handleUnSelect = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      dispatch(setSelectedBook(null));
      dispatch(setSelectedBookPages([]));
      setIsTransitioning(false);
    }, 500);
  };

  const handlePageClick = (
    htmlContent: string,
    cssContent: string,
    width = "400px",
    height = "800px"
  ) => {
    const newWindow = window.open(
      "",
      "_blank",
      `width=${width},height=${height}`
    );
    if (newWindow) {
      const document = newWindow.document;
      document.write(`
        <html>
          <head>
            <style>${cssContent}</style>
          </head>
          <body>${htmlContent}</body>
        </html>
      `);
      document.close();
    }
  };

  const handleDeletePage = (pageId: string) => {
    if (window.confirm("Are you sure you want to delete this page?")) {
      console.log("Page ID to delete:", pageId);
      dispatch(deletePage(pageId));
    }
  };

  const renderPage = (page: BookPage) => (
    <div key={page._id} className={styles.pageItem}>
      <PageThumbnail
        htmlContent={page.pageContent.html}
        cssContent={page.pageContent.css}
        pageNumber={page.pageNumber}
        imageUrl={page.pageContent.images.mainImg}
        width="150px"
        height="250px"
        onClick={() =>
          handlePageClick(
            page.pageContent.html,
            page.pageContent.css,
            page.width,
            page.height
          )
        }
      />
      <div className={styles.miniButtonsContainer}>
        <MiniButton onClick={() => editMode(page.pageNumber)}>
          <EditIcon />
        </MiniButton>
        <MiniButton
          onClick={() => page._id && handleDeletePage(page._id)}
          secondary
        >
          <DeleteIcon />
        </MiniButton>
      </div>
    </div>
  );

  const navigateToCreateNewBook = () => {
    navigate("/dashboard/create-new-book");
  };

  return (
    <div
      className={`${styles.booksContainer} ${
        isTransitioning ? styles.hidden : ""
      }`}
    >
      {selectedBook ? (
        <>
          <div className={styles.buttonsContainer}>
            <MainButton label="Create a Page" onClick={onShow} />
            <MiniButton onClick={handleUnSelect} secondary>
              <CloseIcon />
            </MiniButton>
          </div>
          <h1
            className={`${styles.header} ${
              isTransitioning ? styles.hidden : ""
            }`}
          >
            Selected Book:
          </h1>
          <div
            className={`${styles.booksGrid} ${
              isTransitioning ? styles.hidden : ""
            }`}
          >
            <BookPreviewCard {...selectedBook} isSelector />
          </div>
          <div className={styles.pagesContainer}>
            {selectedBookPages && selectedBookPages.length > 0 ? (
              selectedBookPages.map((page) => renderPage(page))
            ) : (
              <p>No pages available for this book.</p>
            )}
          </div>
        </>
      ) : (
        <div>
          <div className={styles.searchContainer}>
            <InputField
              type="text"
              placeholder="Search"
              onChange={handleSearch}
            />
          </div>
          <h1 className={styles.header}>Choose a Book.</h1>
          <div className={styles.buttonsContainer}>
            <MainButton
              label="Create a New Book"
              onClick={navigateToCreateNewBook}
            />
          </div>
          <div className={styles.booksGrid}>
            {books.map((book) => (
              <BookPreviewCard
                key={book._id}
                {...book}
                isSelector
                onClick={() => handleClick(book)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BookSelector;
